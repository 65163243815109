/** @jsxRuntime classic */
/** @jsx jsx */
import { graphql, navigate, PageProps } from "gatsby"
import { jsx, Box, Grid, Container } from "theme-ui"

import type { NewsArticleTypeQuery } from "../../graphql-types"

import BlogCard from "components/BlogCard"
import BlogNav from "components/BlogNav"
import Layout from "components/Layout"
import PageHero from "components/PageHero"
import Pagination from "components/Pagination"

interface NewsPageType extends PageProps {
  data: NewsArticleTypeQuery
  pageContext: {
    currentPage: number
    limit: number
    skip: number
    slug: string
    totalPages: number
  }
}

const NewsPage = ({ data, pageContext, ...props }: NewsPageType) => {
  const articleTypeLinks = data.allStrapiArticleType.edges.map(article => ({
    text: article.node.name as string,
    href: `/news/${article.node.slug}#blog-nav` as string,
  }))

  const handlePagination = (e: any) => {
    const pageSelected = e.selected
    const url = `/news/${pageContext.slug}/${pageSelected + 1}`
    pageSelected === 1 ? navigate(url) : navigate(`/news/${pageContext.slug}`)
  }

  const currentPath = `${props.path}#blog-nav`

  return (
    <Layout seo={data.strapiArticleType?.seo} location={props.location}>
      <PageHero
        title="news & events"
        content="Stay up to date on our latest product releases, Team Aguila news, and upcoming events!"
      />

      <BlogNav currentPath={currentPath} links={articleTypeLinks} />

      <Box as="section" sx={{ py: [null, null, null, 16], my: 16 }}>
        <Container>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
              rowGap: ["25px", null, "50px"],
              columnGap: "22px",
            }}
          >
            {data.allStrapiArticle?.edges.map((article, index) => (
              <BlogCard
                key={index}
                srcUrl={`/news/${data.strapiArticleType?.slug}/${article?.node.slug}`}
                date={article?.node.Publish_Date}
                title={article?.node.title as string}
              />
            ))}
          </Grid>

          {pageContext.totalPages > 1 && (
            <Pagination
              forcePage={pageContext.currentPage - 1}
              onPageChange={handlePagination}
              pageCount={pageContext.totalPages}
            />
          )}
        </Container>
      </Box>
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query NewsArticleType($slug: String!, $skip: Int!, $limit: Int!) {
    strapiArticleType(slug: { eq: $slug }) {
      name
      slug
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiArticleType {
      edges {
        node {
          slug
          name
        }
      }
    }
    allStrapiArticle(
      skip: $skip
      limit: $limit
      filter: { article_type: { slug: { eq: $slug } } }
      sort: { fields: Publish_Date, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          Publish_Date
        }
      }
    }
  }
`
